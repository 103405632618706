import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useI18next } from "gatsby-plugin-react-i18next"
import { Gallery } from "../components/Gallery"
import SimpleReactLightbox from "simple-react-lightbox"

export default function Galeri({ data }) {
  const { language, t } = useI18next()
  const [kategoriler, setKategoriler] = useState([])
  const [galeriler, setGaleriler] = useState([])

  useEffect(() => {
    if (data) {
      const cats = data?.kategoriler?.edges.map(edge => edge.node.frontmatter)
      let dogs = data?.galeri?.edges?.map(edge => edge.node.frontmatter)
      dogs = dogs?.map(dog => {
        const cat = cats?.find(cat => cat.title === dog.kategori)
        return {
          ...dog,
          galeri_sirasi: cat?.galeri_sirasi,
          src: dog.resim,
          tag: language === "tr" ? cat.title : cat.title_en,
          title: language === "tr" ? dog.baslik : dog.baslik_en,
        }
      })
      setKategoriler(
        cats.map(cat => ({
          name: language === "tr" ? cat.title : cat.title_en,
          status: false,
        }))
      )
      setGaleriler(
        dogs.sort((a, b) => {
          return a.galeri_sirasi > b.galeri_sirasi ? 1 : -1
        })
      )
    }
    function addScrolled() {
      document.getElementById("header").classList.add("scrolled");
    }
    addScrolled()
  }, [data, language])

  return (
    <Layout>
      <SEO title="Galeri" />
      <div className="full-containerBg">
        <div className="full-container">
          <div className="section gallery page">
            <div className="title">{t("galleryTitle")}</div>
            <SimpleReactLightbox>
              <Gallery filters={kategoriler} images={galeriler} />
            </SimpleReactLightbox>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query Galeri($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    kategoriler: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "galeri_kategori" } } }
      sort: { order: ASC, fields: frontmatter___galeri_sirasi }
    ) {
      edges {
        node {
          frontmatter {
            galeri_sirasi
            title
            title_en
          }
        }
      }
    }
    galeri: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "galeri" } } }
    ) {
      edges {
        node {
          frontmatter {
            kategori
            resim
            baslik
            baslik_en
          }
        }
      }
    }
  }
`
